.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 132px;
  padding: 12px;
  background-color: var(--primary);
}

.logo__wrap {
  width: 120px;
  height: 85.22px;
}

.nav {
  display: none;
  position: absolute;
  top: 84px;
  left: 0;
  background: var(--primary);
  width: 100%;
  z-index: 9999;
}

.open {
  display: block;
}

.menu__wrap {
  position: relative;
}

.menu {
  position: relative;
  width: 100%;
  text-align: center;
}

.menu__link {
  font-family: var(--ff-semibold);
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
}

.nav__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 12px;
  padding-bottom: 24px;
}

.has__submenu {
  padding-bottom: 13px;
}

.has__submenu::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  width: 18px;
  height: 18px;
  background: url(../../public/icons/navbar_submenu.svg) no-repeat center;
}

.sub_menus {
  width: 100%;
  text-align: center;
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.sub_menus__open {
  display: flex;
}

.content__icons {
  display: flex;
  align-items: center;
}

.icons__icon__wrap,
.icons__icon__wrap a {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.icons__icon__wrap__language {
  display: none;
}

.icon__text {
  font-family: var(--font-family-bold);
  font-size: 18px;
  color: #fff;
  text-decoration: none;
}

.icons__divider {
  width: 1px;
  height: 34px;
  background: #fff;
  margin: 0 10px;
  border: none;
}

.hamburguer__icon {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .icons__icon__wrap__hamburguer,
  .icons__divider {
    display: none;
  }

  .nav {
    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    background: none;
  }

  .nav__container {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  .menu {
    width: auto;
  }

  .has__submenu {
    padding-bottom: 0;
  }

  .has__submenu::after {
    bottom: -13px;
  }

  .sub_menus {
    position: absolute;
    left: 50%;
    min-width: 150px;
    width: auto;
    background: #fff;
    border-radius: var(--border-radius);
    padding: 12px;
    transform: translate(-50%, 12px);
    border: 1px solid #dfdfdf;
  }

  .sub_menus .menu__link {
    color: var(--primary);
  }
}
