.reservationBar {
  width: 100%;
  height: 82px;
  border-radius: var(--border-radius);
  padding: 10px 22px;

}

.reservationBar__content_mobile {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content__title {
  font-family: var(--font-family-bold);
  font-size: 20px;
  color: var(--typography-green, #106B55);
}

.reservationBar__content_desktop {
  /* display: none; */
  width: 100%;
  height: 140%;
  padding: 0 20px;
  position: relative;
}

.reservationBar__steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  height: 100%;
  margin: 0 auto;
}

.icon {
  position: absolute;
  top: -22px;
  z-index: 1;
}

.reservationBar__step {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 150px; */
  position: relative;
  flex: 1;
}

.reservationBar__step::after {
  content: '';
  width: 100%;
  height: 4px;
  position: absolute;
  top: -10px;
  left: 50%;
  background: #666;
  transition: background-color 0.3s ease-in-out;
}

.reservationBar__step::before {
  content: '';
  width: 100%;
  height: 4px;
  position: absolute;
  top: -10px;
  right: 50%;
  background: #666;
}

.reservationBar__step_active::after {
  background: #106B55; /* Verde para as etapas ativas */
  transition: background-color 0.3s ease-in-out; /* Suave transição de cor */
}
.reservationBar__step_active::before {
  background: #106B55; /* Verde para as etapas ativas */
  transition: background-color 0.3s ease-in-out; /* Suave transição de cor */
}
.reservationBar__step_left::before{
  background: #106B55; /* Verde para as etapas ativas */
  transition: background-color 0.3s ease-in-out; /* Suave transição de cor */
}

.icon {
  transition: fill 0.3s ease-in-out; /* Transição suave para os ícones também */
}

.reservationBar__step:first-child::before {
  content: none;
}

.reservationBar__step:last-child::after {
  content: none;
}

.reservationBar__step__title {
  font-family: var(--ff-semibold);
  font-size: 16px;
  color: var(--typography-green, #106B55);
  opacity: 0.8;
}

.reservationBar__step__subtitle {
  color: var(--typography-green, #106B55);
  font-size: 14px;
  text-decoration: underline;
  position: absolute;
  top: 20px;
}

.active {
  font-family: var(--ff-semibold);
  font-size: 16px;
  opacity: 1;
}

.hasValue {
  font-family: var(--ff-semibold);
  font-size: 16px;
}

.reservationBar__step__value {
  font-family: var(--ff-bold);
  font-size: 18px;
  color: var(--typography-green, #106B55);
  white-space: nowrap;
}

@media (min-width: 768px) {
  .reservationBar__content_desktop {
    display: block;
  }
}

@media (max-width: 1024px) {
  .reservationBar__content_desktop::after {
    width: 86%;
  }
}

@media (max-width: 768px) {
  .reservationBar__content_desktop::after {
    left: 8%;
    width: 84%;
  }
}

@media (max-width: 768px) {
  .reservationBar {
    height: 25px;
    padding: 0;
  }
  .reservationBar__content_desktop {
    height: 25px;
  }
  .icon,
  .reservationBar__step::after,
  .reservationBar__step::before {
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 1020px) {
  .reservationBar__step__title {
    display: none;
 }
 .reservationBar__step__subtitle {
  display: none;
}
}