.text__field {
  position: relative;
  width: 100%;
  height: 50px;
  font-family: var(--font-family);
  font-size: 16px;
  color: #342e37;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding-left: 12px;
  /* padding-top: 12px; */
  /* padding-bottom: 11px; */
}

.text__field:hover,
.text__field:focus {
  border: 1px solid #000;
  border-radius: 4px;
  outline: none;
}


.cupom__field {
  width: 100%;
}

@media (max-width: 768px) {
  .cupom__field {
    width: 100%;
  }
}

input.text__field:-internal-autofill-selected {
  background-color: #fff !important;
  background: #fff !important;
  color: #342e37 !important;
  appearance: unset !important;
}

.date__field {
  position: relative;
}

.field__has_error {
  background: #ffeaea;
}

.react-select-container {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  height: 50px;
}

.react-select-container .react-select__menu {
  position: absolute;
  border-radius: var(--border-radius);
  z-index: 10;
}

.react-select-container .react-select__control {
  border: none;
  border-radius: 0;
}

.react-select-container.field__has_error .react-select__control {
  background-color: var(--error);
}

.react-select-container .react-select__value-container {
  padding-left: 0;
}

.react-select-container .react-select__indicator-separator {
  display: none;
}

.react-select-container .react-select__control:hover,
.react-select-container .react-select__control:focus,
.react-select-container .react-select__control--is-focused,
.react-select-container .react-select__control--menu-is-open {
  border: none !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  border-color: none;
  overflow: hidden;
}

.react-select-container:hover,
.react-select-container:focus {
  border: 1px solid #000;
}

.react-select-container .react-select__control {
  height: 100%;
}

.react-select-container
  .react-select__indicator.react-select__dropdown-indicator {
  display: none;
}

.react-select-container .react-select__option--is-focused,
.react-select-container
  .react-select__option.react-select__option--is-selected {
  background-color: var(--primary);
  color: #fff;
}

.react-select-container .react-select__placeholder {
  font-size: 16px;
  padding-left: 12px;
}

.react-select-container .react-select__single-value {
  font-family: var(--font-family);
  font-size: 16px;
  margin-left: 12px;
  color: #342e37;
}
/* 
.date .react-datepicker__input-container::after {
  content: '';
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  top: 10px;
  right: 0;
  background: url(../public/icons/calendario.svg);
  z-index: 2;
} */

.time .react-datepicker__input-container::after {
  content: '';
  width: 18px;
  height: 19px;
  display: block;
  position: absolute;
  top: 10px;
  right: 0;
  background: url(../public/icons/clock.svg);
  z-index: 2;
}

.react-datepicker__portal .react-datepicker {
  border: none;
  border-radius: var(--border-radius);
}

.react-datepicker__portal .react-datepicker__header {
  background: #fff;
  border: none;
}

.react-datepicker__portal
  .react-datepicker__header
  .react-datepicker__current-month {
  font-weight: normal;
  font-family: var(--font-family-semibold);
  font-size: 18px;
}

.react-datepicker__current-month {
  text-transform: capitalize;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: var(--primary) !important;
  color: #fff !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selected) {
  background-color: var(--primary) !important;
}

.react-datepicker__portal
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--primary) !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--primary-hover) !important;
}

input[type='checkbox'] {
  position: relative;
  top: 5px;
  width: 17px;
  height: 17px;
  border: 1px solid #707070;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='checkbox']:hover {
  border-color: #000;
}

input[type='checkbox']:checked {
  border: none;
  background: url(../public/icons/green-checked.svg) center no-repeat;
  background-color: #106b55;
}

input[type='checkbox'].field__has_error {
  border: none;
}
