:root {
  --primary: #f20000;
  --primary-hover: #ff4242;
  --success: #106b55;
  --success-hover: #26a485;
  --warning: #ffd03c;
  --warning-hover: #ffff00;
  --disabled: #dfdfdf;
  --warning: #ffe69c;
  --error: #ffeaea;
  --title: #152536;
  --text: #342e37;
  --white: #fff;
  --font-family: 'ommnes-regular', sans-serif;
  --font-family-semibold: 'ommnes-semibold', sans-serif;
  --font-family-bold: 'ommnes-bold', sans-serif;
  --ff: 'ommnes-regular', sans-serif;
  --ff-semibold: 'ommnes-semibold', sans-serif;
  --ff-bold: 'ommnes-bold', sans-serif;
  --border-radius: 5px;
  --viewport-small: 576px;
  --viewport-medium: 768px;
  --viewport-large: 992px;
  --viewport-extra-large: 1200px;
  --viewport-extra-extra-large: 1400px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  color: var(--text);
}

body {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.bold {
  font-family: var(--font-family-bold);
}

.semibold {
  font-family: var(--font-family-semibold);
}

.desktopOnly {
  display: none;
}

.mt-12 {
  margin-top: 12px;
}

@media (min-width: 768px) {
  .desktopOnly {
    display: block;
  }
}

@media (prefers-color-scheme: dark) {}

.react-tiny-popover-container {
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: var(--border-radius);
  margin-top: 20px;
}

.react-tiny-popover-container__arrow,
.react-tiny-popover-container__arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.react-tiny-popover-container__arrow {
  visibility: hidden;
}

.react-tiny-popover-container__arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

/* markdown content */
.markdown__content h1 {
  color: var(--primary);
  margin-bottom: 40px;
}

.markdown__content h2 {
  margin-top: 30px;
}

.markdown__content h2:first-child {
  margin-top: 0;
}

.markdown__content h2,
.markdown__content p {
  margin-bottom: 12px;
}

.markdown__content a {
  color: #17a2b8;
}

.markdown__content a:hover {
  text-decoration: underline;
}

.markdown__content a:visited {
  opacity: .8;
}