@font-face {
  font-family: 'ommnes-regular';
  font-display: auto;
  src: url('../public/fonts/omnes-regular-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes-semibold';
  font-display: auto;
  src: url('../public/fonts/omnes-semibold-regular.woff2') format('woff2'),
    url('../public/fonts/omnes-semibold-regular.woff') format('woff'),
    url('../public/fonts/omnes-semibold-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes semi';
  src: url('../public/fonts/omnes-semibold-italic-webfont.woff2')
      format('woff2'),
    url('../public/fonts/omnes-semibold-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes';
  font-display: auto;
  src: url('../public/fonts/omnes-black-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes';
  font-display: auto;
  src: url('../public/fonts/omnes-blackitalic-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes';
  font-display: auto;
  src: url('../public/fonts/omnes-bold-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes-bold';
  font-display: auto;
  src: url('../public/fonts/omnes-bold-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes';
  font-display: auto;
  src: url('../public/fonts/omnes-bolditalic-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes';
  font-display: auto;
  src: url('../public/fonts/omnes-extralight-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-extralight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes';
  font-display: auto;
  src: url('../public/fonts/omnes-light-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes';
  font-display: auto;
  src: url('../public/fonts/omnes-lightitalic-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-lightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes';
  font-display: auto;
  src: url('../public/fonts/omnes-medium-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes-medium';
  font-display: auto;
  src: url('../public/fonts/omnes-medium-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes';
  font-display: auto;
  src: url('../public/fonts/omnes-mediumitalic-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-mediumitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ommnes';
  font-display: auto;
  src: url('../public/fonts/omnes-regularitalic-webfont.woff2') format('woff2'),
    url('../public/fonts/omnes-regularitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
