.layout {
  position: relative;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .download__app {
    padding-top: 50px;
  }
}


.main__container {
  padding-bottom: 25px;
  max-width: 1400px;
  margin: 0 auto;
  border-radius: 15px;
  border: 1px solid #D1D1D1;
  background: #FFF;
  margin-top: -40px;
}

.content__title {
  display: flex;
  justify-content: center;
  font-family: var(--font-family-bold);
  font-size: 30px;
  color: #1E1E1E;
  margin: 12px 0;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.loading__content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reservationBar__container {
  display: flex;
  align-items: center;
  background: #F2F4F3;
  border-radius: 15px 15px 0px 0px;
  height: 100px;
}

.cards__container {
  padding: 0 100px;
}

@media (max-width: 768px) {
  .reservationBar__container {
    height: 25px;
    background: #FFF;
    margin-top: 38px;
  }

  .main__container {
    border-radius: 0;
  }

  .cards__container {
    padding: 0 26px;
  }
}

@media (max-width: 1024px) {
  .layout_floater {
    margin-bottom: 0;
  }

  .main__container_floater {
    padding-bottom: 156px;
  }
}