.adyen-checkout-form-instruction {
  display: none;
}

.adyen-checkout__label {
  display: block;
  margin: 0;
  margin-bottom: 8px;
}

.adyen-checkout__label .adyen-checkout__label__text {
  color: #342e37;
  font-family: var(--font-family-semibold);
  font-size: 16px;
  padding: 0;
  line-height: unset;
}

.adyen-checkout__field .adyen-checkout__label--focused .adyen-checkout__label__text {
  color: #342e37;
}


.adyen-checkout__field .adyen-checkout__input {
  padding: 0;
  caret-color: #f20000;
  position: relative;
  width: 100%;
  height: 50px;
  font-family: var(--font-family);
  font-size: 16px;
  color: #342e37;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.adyen-checkout__field .adyen-checkout__input--focus,
.adyen-checkout__field .adyen-checkout__input--focus:hover,
.adyen-checkout__field .adyen-checkout__input:active,
.adyen-checkout__field .adyen-checkout__input:active:hover,
.adyen-checkout__field .adyen-checkout__input:focus,
.adyen-checkout__field .adyen-checkout__input:focus:hover {
  outline: none;
  box-shadow: none;
  border: 1px solid #000;
  border-radius: 4px;
}


.adyen-checkout__field .adyen-checkout__input--error,
.adyen-checkout__field .adyen-checkout__input--error:hover,
.adyen-checkout__field .adyen-checkout__input--invalid,
.adyen-checkout__field .adyen-checkout__input--invalid:hover {
  background: #ffeaea;
  border-color: unset
}

.adyen-checkout__field .adyen-checkout__error-text {
  margin-top: 12px;
  font-size: 14px;
  color: var(--primary);
}

.js-iframe {
  border: none;
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
